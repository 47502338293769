import { gql, useQuery, useMutation } from "@apollo/client"
import React, { useState, useEffect } from "react"
import { useIntl } from "react-intl"
import PropTypes, { InferProps } from 'prop-types'

const STUDENT_TYPES_QUERY = gql`
  query AllStudentTypes {
    all_types {
      id
      code
      label
    }
  }
`;

const ADD_STUDENT_MUTATION = gql`
mutation AddStudent($input: StudentInput!) {
  add_student(input: $input)
}
`;

const RegisterForm: any = ({setIsSuccess, setIsError, ...rest}: InferProps<typeof RegisterForm.propTypes>) => {
  const intl = useIntl()

  const [isFormOnError, setIsFormOnError] = useState<boolean>(false)
  const [isAgeError, setIsAgeError] = useState<boolean>(false);
  const [isPhoneError, setIsPhoneError] = useState<boolean>(false);
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isParentPhoneError, setIsParentPhoneError] = useState<boolean>(false);
  const [isParentEmailError, setIsParentEmailError] = useState<boolean>(false);

  //Form Fields
  const [studentFirstName, setStudentFirstName] = useState<string>("");
  const [studentLastName, setStudentLastName] = useState<string>("");
  const [studentAge, setStudentAge] = useState<string>("");
  const [studentDateOfBirth, setStudentDateOfBirth] = useState<string>("");
  const [studentType, setStudentType] = useState<string>("1");
  const [studentPhone, setStudentPhone] = useState<string>("");
  const [studentEmail, setStudentEmail] = useState<string>("");
  const [studentAddress, setStudentAddress] = useState<string>("");
  const [parentFirstName, setParentFirstName] = useState<string>("");
  const [parentLastName, setParentLastName] = useState<string>("");
  const [parentPhone, setParentPhone] = useState<string>("");
  const [parentEmail, setParentEmail] = useState<string>("");

  // Graphql query for student types
  const { loading, error, data } = useQuery(STUDENT_TYPES_QUERY);
  const [onAddStudent] = useMutation(ADD_STUDENT_MUTATION, {
    variables: {
        input : {
            student_type_id: studentType,
            first_name: studentFirstName,
            last_name: studentLastName,
            age: parseInt(studentAge),
            date_of_birth: studentDateOfBirth,
            phone_number: studentPhone,
            email: studentEmail,
            address: studentAddress,
            parent: ['1', '2'].includes(studentType) ? {
                first_name: parentFirstName,
                last_name: parentLastName,
                phone_number: parentPhone,
                email: parentEmail,
                address: studentAddress,

            } : null
        }
    }
  });

  //submitting the form
  const submitForm: React.FormEventHandler<HTMLButtonElement> = (e: React.FormEvent<HTMLButtonElement>) => {
    let formValid: boolean = true;

    let regExpNumbers = new RegExp('^[0-9]');
    if(studentAge === "" || !regExpNumbers.test(studentAge) || studentAge.length > 2){
        setIsAgeError(true);
    }

    if(studentPhone === "" || !regExpNumbers.test(studentPhone) || studentPhone.length !== 8){
        setIsPhoneError(true);
    }

    let regExpEmail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if(!regExpEmail.test(studentEmail)){
        setIsPhoneError(true);
    }

    if(['1', '2'].includes(studentType)){
        if(parentPhone === "" || !regExpNumbers.test(parentPhone) || parentPhone.length !== 8){
            setIsParentPhoneError(true);
        }
    
        if(!regExpEmail.test(parentEmail)){
            setIsParentEmailError(true);
        }
    }

    if(isAgeError || isPhoneError || isEmailError){
        formValid = false;
        setIsFormOnError(true);
        
    }

    if(['1', '2'].includes(studentType)){
        if(isParentPhoneError || isParentEmailError){
            formValid = false;
            setIsFormOnError(true);
        }
    }
    if(formValid){
        onAddStudent().then((data) => {
            setIsSuccess(true);
        }).catch((error) => {
            setIsError(true);
        })
    }
  };

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error...</p>

  return (
      <div className="register-form">
        <form>
          <h2
            style={
              intl.locale === "ar"
                ? { textAlign: "right" }
                : { textAlign: "left" }
            }
          >
            {intl.formatMessage({ id: "registerationstudentinfo" })}
          </h2>
          <p
            style={
              intl.locale === "ar"
                ? { textAlign: "right" }
                : { textAlign: "left" }
            }
            className="description"
          >
            {intl.formatMessage({ id: "registerationstudentinfodescription" })}
          </p>
          <hr />
          <div className="form-group">
            <label
              style={
                intl.locale === "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              {intl.formatMessage({ id: "registrationstudenttype" })}{" "}
            </label>
            <select
              value={studentType}
              onChange={e => {
                setStudentType(e.target.value)
              }}
              className="form-control"
              id="studenttype"
              required
            >
              {data?.all_types.map((el: any) => {
                return <option key={el.code} value={el.id}>{el.label}</option>
              })}
            </select>
          </div>

          <div className="form-group">
            <label
              style={
                intl.locale === "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              {intl.formatMessage({ id: "registrationfirstname" })}{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={intl.formatMessage({
                id: "registrationfirstnamehint",
              })}
              value={studentFirstName}
              onChange={e => {
                setStudentFirstName(e.target.value)
              }}
            />
          </div>

          <div className="form-group">
            <label
              style={
                intl.locale === "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              {intl.formatMessage({ id: "registrationlastname" })}{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={intl.formatMessage({
                id: "registrationlastnamehint",
              })}
              value={studentLastName}
              onChange={e => {
                setStudentLastName(e.target.value)
              }}
            />
          </div>

          <div className="form-group">
            <label
              style={
                intl.locale === "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              {intl.formatMessage({ id: "registrationage" })}{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={intl.formatMessage({ id: "registrationagehint" })}
              value={studentAge}
              onChange={e => {
                setStudentAge(e.target.value)
              }}
            />
            {
                isAgeError &&
                <p className="description" style={
                    intl.locale === "ar"
                  ? { textAlign: "right", color: '#dc3545', marginTop: 10 }
                  : { textAlign: "left", color: '#dc3545', marginTop: 10 }
                   }>{intl.formatMessage({ id: "studentageerrormessage" })}</p>
            }
          </div>

          <div className="form-group">
            <label
              style={
                intl.locale === "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              {intl.formatMessage({ id: "registrationdateofbirth" })}{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={intl.formatMessage({
                id: "registrationdateofbirthhint",
              })}
              value={studentDateOfBirth}
              onChange={e => {
                setStudentDateOfBirth(e.target.value)
              }}
            />
          </div>
          <div className="form-group">
            <label
              style={
                intl.locale === "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              {intl.formatMessage({ id: "registrationphone" })}{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={intl.formatMessage({ id: "registrationphonehint" })}
              value={studentPhone}
              onChange={e => {
                setStudentPhone(e.target.value)
              }}
            />
            {
                isPhoneError &&
                <p className="description" style={
                    intl.locale === "ar"
                  ? { textAlign: "right", color: '#dc3545', marginTop: 10 }
                  : { textAlign: "left", color: '#dc3545', marginTop: 10 }
                   }>{intl.formatMessage({ id: "studentphoneerrormessage" })}</p>
            }
          </div>

          <div className="form-group">
            <label
              style={
                intl.locale === "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              {intl.formatMessage({ id: "registrationemail" })}{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={intl.formatMessage({ id: "registrationemailhint" })}
              value={studentEmail}
              onChange={e => {
                setStudentEmail(e.target.value)
              }}
            />
            {
                isEmailError &&
                <p className="description" style={
                    intl.locale === "ar"
                  ? { textAlign: "right", color: '#dc3545', marginTop: 10 }
                  : { textAlign: "left", color: '#dc3545', marginTop: 10 }
                   }>{intl.formatMessage({ id: "studentemailerrormessage" })}</p>
            }
          </div>
          <div className="form-group">
            <label
              style={
                intl.locale === "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              {intl.formatMessage({ id: "registrationadress" })}{" "}
            </label>
            <textarea
              cols="30"
              rows="4"
              className="form-control"
              placeholder={intl.formatMessage({ id: "registrationadresshint" })}
              value={studentAddress}
              onChange={e => {
                setStudentAddress(e.target.value)
              }}
            />
          </div>

          {
          ['1', '2'].includes(studentType) && (
              <React.Fragment>
                <hr />

                <h2
                  style={
                    intl.locale === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }
                >
                  {intl.formatMessage({ id: "registerationparentinfo" })}
                </h2>
                <p
                  style={
                    intl.locale === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }
                  className="description"
                >
                  {intl.formatMessage({
                    id: "registerationparentinfodescription",
                  })}
                </p>

                <div className="form-group">
                  <label
                    style={
                      intl.locale === "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {intl.formatMessage({ id: "registrationfirstname" })}{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "registrationparentfirstnamehint",
                    })}
                    value={parentFirstName}
                    onChange={e => {
                      setParentFirstName(e.target.value)
                    }}
                  />
                </div>

                <div className="form-group">
                  <label
                    style={
                      intl.locale === "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {intl.formatMessage({ id: "registrationlastname" })}{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "registrationparentlastnamehint",
                    })}
                    value={parentLastName}
                    onChange={e => {
                      setParentLastName(e.target.value)
                    }}
                  />
                </div>

                <div className="form-group">
                  <label
                    style={
                      intl.locale === "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {intl.formatMessage({ id: "registrationphone" })}{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "registrationparentphonehint",
                    })}
                    value={parentPhone}
                    onChange={e => {
                      setParentPhone(e.target.value)
                    }}
                  />
                  {
                    isParentPhoneError &&
                    <p className="description" style={
                        intl.locale === "ar"
                      ? { textAlign: "right", color: '#dc3545', marginTop: 10 }
                      : { textAlign: "left", color: '#dc3545', marginTop: 10 }
                       }>{intl.formatMessage({ id: "parentphoneerrormessage" })}</p>
                }
                </div>

                <div className="form-group">
                  <label
                    style={
                      intl.locale === "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {intl.formatMessage({ id: "registrationemail" })}{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "registrationparentemailhint",
                    })}
                    value={parentEmail}
                    onChange={e => {
                      setParentEmail(e.target.value)
                    }}
                  />
                  {
                    isParentEmailError &&
                    <p className="description" style={
                        intl.locale === "ar"
                      ? { textAlign: "right", color: '#dc3545', marginTop: 10 }
                      : { textAlign: "left", color: '#dc3545', marginTop: 10 }
                       }>{intl.formatMessage({ id: "parentemailerrormessage" })}</p>
                }
                </div>
              </React.Fragment>
            )}
            {
                isFormOnError &&
                <p className="description" style={
                    intl.locale === "ar"
                  ? { textAlign: "right", color: '#dc3545', marginTop: 10 }
                  : { textAlign: "left", color: '#dc3545', marginTop: 10 }
                   }>{intl.formatMessage({ id: "registrationformmessage" })}</p>
            }
            
          <button type="button" onClick={(e: React.FormEvent<HTMLButtonElement>) => {submitForm(e)}}>
            {intl.formatMessage({ id: "register" })}
          </button>
        </form>
      </div>
    )
};


RegisterForm.propTypes = {
    setIsSuccess: PropTypes.func, 
    setIsError: PropTypes.func,

};

export default RegisterForm
