import React, { useState } from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import LoginForm from "../components/Registration/LoginForm"
import RegisterForm from "../components/Registration/RegisterForm"
import { useIntl } from "react-intl"
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client"

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: "http://141.94.223.106:8001/graphql/",
    fetch,
    
  }),
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Access-Control-Request-Method": "POST"
  },
})
const Index = () => {
  const intl = useIntl()

  //page behaviour
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  return (
    <ApolloProvider client={client}>
      <Layout>
        <Navbar />
        <PageBanner
          pageTitle={intl.formatMessage({ id: "registrationpagetitle" })}
          homePageText={intl.formatMessage({ id: "home" })}
          homePageUrl="/"
          activePageText={intl.formatMessage({ id: "registrationformpage" })}
        />

        <section className="profile-authentication-area ptb-100">
          <div className="container" style={{ display: "inline-block" }}>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                {!isSuccess && !isError && (
                  <RegisterForm
                    setIsError={setIsError}
                    setIsSuccess={setIsSuccess}
                  />
                )}
                {isSuccess && !isError && (
                  <div
                    className="container"
                    style={
                      intl.locale === "ar"
                        ? { textAlign: "right", display: "inline-block" }
                        : { textAlign: "left", display: "inline-block" }
                    }
                  >
                    <p
                      style={{
                        padding: 5,
                        color: "#fff",
                        backgroundColor: "#28a745",
                      }}
                    >
                      تم التسجيل بنجاح
                    </p>
                    <blockquote
                      style={
                        intl.locale === "ar"
                          ? { textAlign: "right" }
                          : { textAlign: "left" }
                      }
                      className="blockquote"
                    >
                      <p>
                        لقد تمت عملية التسجيل بنجاح سيتم الإتصال بكم قريبا عبر
                        البريد الإلكتروني والهاتف. شكرا لثقتكم و إلى اللقاء
                        قريبا.
                      </p>
                    </blockquote>
                  </div>
                )}
                {!isSuccess && isError && (
                  <div
                    className="container"
                    style={
                      intl.locale === "ar"
                        ? { textAlign: "right", display: "inline-block" }
                        : { textAlign: "left", display: "inline-block" }
                    }
                  >
                    <p
                      style={{
                        padding: 5,
                        color: "#fff",
                        backgroundColor: "#dc3545",
                      }}
                    >
                      فشلت عملية التسجيل{" "}
                    </p>
                    <blockquote
                      style={
                        intl.locale === "ar"
                          ? { textAlign: "right" }
                          : { textAlign: "left" }
                      }
                      className="blockquote"
                    >
                      <p>
                        لقد فشلت عملية التسجيل يمكنكم الإتصال بنا عبر البريد
                        الإلكتروني
                      </p>
                      dev@ipalm.tn
                      <p>والهاتف</p>
                      55767884
                      <p>. شكرا لثقتكم و إلى اللقاء قريبا</p>
                    </blockquote>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </Layout>
    </ApolloProvider>
  )
}

export default Index
